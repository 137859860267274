import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Landing",
    component: () => import("../views/LandingView.vue"),
  },
  {
    path: "/create_album",
    name: "CreateAlbum",
    component: () => import("../views/CreateAlbum.vue"),
  },
  {
    path: "/a/:id",
    name: "ViewAlbum",
    component: () => import("../views/AlbumView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
